<template>
  <div class>
    <title-top>我的订单</title-top>
    <div class="bg-white row between h96 align-center"  @click="onZy">
      <div class="row align-center ml50">
        <img class="imgsize32 bg-F7405A mr25" src alt />
        <span class="font28 f-1A1A1A">商城订单</span>
      </div>
      <img class="imgsize32 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
    <div class="row align-center font20 f-999999 h72">
      <span class="ml50">第三方平台订单</span>
    </div>
    <div class="bg-white row between h96 align-center" @click="onOrder(0)">
      <div class="row align-center ml50">
        <img class="imgsize32 bg-F7405A mr25" src alt />
        <span class="font28 f-1A1A1A">淘宝</span>
      </div>
      <img class="imgsize32 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
    <div class="line"></div>
    <div class="bg-white row between h96 align-center" @click="onOrder(1)">
      <div class="row align-center ml50">
        <img class="imgsize32 bg-F7405A mr25" src alt />
        <span class="font28 f-1A1A1A">京东</span>
      </div>
      <img class="imgsize32 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
    <div class="line"></div>
    <div class="bg-white row between h96 align-center" @click="onOrder(2)">
      <div class="row align-center ml50">
        <img class="imgsize32 bg-F7405A mr25" src alt />
        <span class="font28 f-1A1A1A">拼多多</span>
      </div>
      <img class="imgsize32 mr25" src="~images/zyimg/nextbtn.png" alt />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";

export default {
  name: "MyOrderTurn",
  data() {
    return {};
  },
  methods:{
    onZy(){
      this.$router.push('zyorderlist');
    },
    onOrder(index){
      this.$router.push({
        path:'/myorder',
        query:{index:index}
      })
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
